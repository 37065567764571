<template>
	<div class="mainBox">
		<!--日期筛选-->
		<div class="handleBox overviewTime flex flex-jc-fe flex-ai-c">
			<el-radio-group v-model="dateRadio" style="margin-right:10px">
				<!-- <el-radio label="day">日</el-radio> -->
				<el-radio label="week">周</el-radio>
				<el-radio label="month">月</el-radio>
				<el-radio label="year">年</el-radio>
			</el-radio-group>

			<el-date-picker v-show="dateRadio == 'year'" v-model="inputyear" size="mini" type="year" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'month'" v-model="inputmonth" size="mini" type="month" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'week'" v-model="inputweek" size="mini" type="week" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getList()">
			</el-date-picker>
			<!-- <el-date-picker v-show="dateRadio == 'day'" v-model="inputDay" size="mini" type="date" value-format="yyyy-MM-dd" format="yyyy年MM月dd日" placeholder="选择天" @change="getList()">
			</el-date-picker> -->
		</div>

		<div class="overviewBox">
			<!--数据统计-->
			<div class="count">
				<div class="tips">数据统计</div>
				<div class="">
					<div class="z">新增用户：<span v-if="tongji">{{tongji.user_count}}</span>人</div>
					<p class="under"></p>
					<div class="z">会员总数：<span v-if="tongji">{{tongji.vip_count}}</span>个</div>
					<p class="under"></p>
					<div class="z">订单金额：<span v-if="tongji">{{tongji.amount}}</span>元</div>
					<p class="under"></p>
					<div class="z">订单总数：<span v-if="tongji">{{tongji.order_count}}</span>个</div>
					<p class="under"></p>
					<!-- <div class="z">待配送：<span v-if="tongji">{{tongji.delivery}}</span>个</div>
					<p class="under"></p>
					<div class="z">已配送：<span v-if="tongji">{{tongji.delivered}}</span>个</div>
					<p class="under"></p> -->
					<div class="z">配送超时：<span v-if="tongji">{{tongji.timeout}}</span>个</div>
					<p class="under"></p>
					<div class="z">订单退货：<span v-if="tongji">{{tongji.refund}}</span>个</div>
				</div>
			</div>

			<!--门店统计-->
			<div class="" style="margin-top:15px;">
				<!-- <div class="tips" style="">各店新增统计</div> -->
				<div class="tabBorder flex">
					<el-row :gutter="10" type="flex" justify="space-between" class="storeCountTotal">
						<el-col v-for="(item,index) in formData.warehouse_count.data" :key="index">
							<el-card class="box-card" >
								<div slot="header" class="clearfix flex flex-ai-c" style="color: #9a88e3;">
									<i class="el-icon-s-shop" style="font-size: 20px;margin-right: 5px;"></i>
									{{item.name}}
								</div>
								<div class="text item">
									<el-row class="countBox" @click.native="jumpPage('/ordinaryUser',item.id)">
										<label>用户数：</label>
										<span>{{item.count}}</span>
									</el-row>
									<el-row class="countBox" @click.native="jumpPage('/memberUser',item.id)">
										<label>会员数：</label>
										<span>{{item.vip_count}}</span>
									</el-row>
								</div>
							</el-card>
						</el-col>
					</el-row>
                </div>
            </div>
            
			<div class="flex" style="height:calc(100% - 280px);margin-top:15px">
				<div class="overviewHouse" style="width: 49%;margin-right: 1%;">
					<div class="tips">门店订单金额对比</div>
					<div ref="homechart" id="homechart" :style="{width: '100%', height: '90%'}"></div>
				</div>
				<div class="overviewHouse" style="width: 50%;">
					<div class="tips">新增用户统计</div>
					<div ref="chart" id="chart" :style="{width: '100%', height: '90%'}"></div>
				</div>
			</div>

			<!--用户访问量统计数据-->
			<!-- <div class="overviewHouse" style="height:calc(100% - 150px);margin-top:15px">
                <div class="tips">新增用户统计</div>
                <div ref="chart" id="chart" :style="{width: '100%', height: '90%'}"></div>
            </div> -->
		</div>
	</div>
</template>
<script>
	var echarts = require('echarts');
	import {
		adminOverviewApi
	} from '@/api/overview.js';
	export default {
		data() {
			return {
				dateRadio: 'month', //年月周日-默认日
				selectDate: null,

				inputyear: '',
				inputmonth: '',
				inputweek: '',
				inputDay: '',
				type: '',

				tongji: {},

				formData: {
					warehouse_count:{}
				},
				tabelHeader:['金域蓝湾店','怡然城店'],
				
			}
		},
		watch: {
			isCollapse() {
				this.resizeWindow();
			},
			'dateRadio': function() {
				var myDate = new Date();
				switch (this.dateRadio) {
					case 'year':
						this.inputyear = String(myDate.getFullYear());
						break;
					case 'month':
						this.inputmonth = (new Date(myDate.getFullYear(), myDate.getMonth(), 1))
						break;
					case 'week':
						this.inputweek = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), (myDate.getDate() - myDate.getDay()) + 1));
						break;
					case 'day':
						this.inputDay = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate()));
						break;
					default:
						break;
				}
				this.getList();
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			//初始化数据
			init() {
				let _this = this;
				this.$nextTick(() => {
					if (_this.dateRadio == 'month') {
						_this.inputmonth = _this.dataFormat(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
						_this.getList();
					}

					window.onresize = () => { //  根据窗口大小调整曲线大小
						_this.resizeWindow();
					}
				})
			},

			//窗口改变统计图自适应
			resizeWindow: function() {
				let _this = this;
				if (_this.$refs.chart) {
					let myChart = echarts.init(_this.$refs.chart);
					let homechart = echarts.init(_this.$refs.homechart);
					myChart.resize();
					homechart.resize();
				}
			},

			//获取统计概况数据
			getList: function() {
				//筛选日期
				this.selectDate = this.dateRadio == 'day' ? this.inputDay : this.dateRadio == 'week' ? this.inputweek : this.dateRadio == 'month' ? this.inputmonth : this.inputyear;
				//筛选类型
				this.type = this.dateRadio == 'day' ? 4 : this.dateRadio == 'week' ? 1 : this.dateRadio == 'month' ? 2 : 3;

				adminOverviewApi({
					type: this.type,
					date: this.selectDate,
				}).then(response => {
					this.formData = response;
					this.tongji = response.tongji;

					this.drawLine();
					this.drawBar();
				})
			},

			// 折线图
			drawLine: function() {
				let _this = this;
				let myChart = echarts.init(this.$refs.chart);
				myChart.setOption({
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							label: {
								show: true
							}
						},
					},
					grid: { //位置
						top: '7%',
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: _this.formData.line.xAxis
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					series: [{
						name: '新增用户',
						type: 'line',
						data: _this.formData.line.yAxis,
						itemStyle: {
							normal: {
								color: '#7B84FF', //设置颜色
								label: {
									show: true,
									position: 'top',
									textStyle: {
										color: '#7B84FF'
									}
								}
							}
						},
					}]
				});
			},
			// 柱形图
			drawBar: function() {
				var myChart = echarts.init(this.$refs.homechart);
				var option = {
					tooltip: {
						trigger: 'axis',
					},
					grid: { //位置
						top: '7%',
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: this.formData.warehouse_amount.xAxis,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					series: [{
						name: '订单金额',
						type: 'bar',
						data: this.formData.warehouse_amount.yAxis,
						barWidth:'30%',
						itemStyle: {
							normal: {
								color: '#7B84FF', //设置柱子颜色
								label: {
									show: true, //柱子上显示值
									position: 'top', //值在柱子上方显示
									textStyle: {
										color: '#7B84FF' //值得颜色
									}
								}
							}
						},
					}]
				};
				myChart.setOption(option);
			},
			jumpPage: function(_path,_id){
				this.$router.push({
					path:_path,
					query:{
						id:_id,
						date:this.selectDate,
						type:this.type
					}
				})
			},
			// 门店统计折线图
			// drawHomeLine: function() {
			// 	let _this = this;
			// 	var myChart = echarts.init(_this.$refs.homechart);
			// 	myChart.setOption({
			// 		tooltip: {
			// 			trigger: 'axis',
			// 			axisPointer: { // 坐标轴指示器，坐标轴触发有效
			// 				label: {
			// 					show: true
			// 				}
			// 			}
			// 		},
			// 		grid: { //位置
			// 			top: '7%',
			// 			left: '0%',
			// 			right: '0%',
			// 			bottom: '0%',
			// 			containLabel: true
			// 		},
			// 		xAxis: {
			// 			type: 'category',
			// 			data: _this.formData.warehouse_count.yAxis
			// 		},
			// 		yAxis: {
			// 			type: 'value',
			// 			splitLine: {
			// 				show: true,
			// 				lineStyle: {
			// 					type: 'dashed'
			// 				}
			// 			}
			// 		},
			// 		series: _this.lineSeries()
			// 	});
			// },

			lineSeries: function() {
				var series = [];
				for (var item of this.formData.warehouse_count.xAxis) {
					series.push({
						name: item.name,
						type: 'line',
						data: item.value,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						smooth: true,
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'top',
								}
							}
						},
					})
				}
				return series;
			},

			//时间转换
			dataFormat: function(time) {
				return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
			}
		},
	}
</script>
<style lang="less">
	.storeCountTotal{
		width: 100%;
		margin: 0 !important;
		>div:first-child{
			padding-left: 0 !important;
		}
		>div:last-child{
			padding-right: 0 !important;
		}
		.countBox{
			cursor: pointer;
			label{
				color: #98a9bc;
				font-size: 14px;
			}
			>span{
				color: #252631;
				font-size: 20px;
			}
		}
		.el-card__body{
			padding: 10px 20px;
		}
	}
</style>
